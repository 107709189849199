<template>
    <div class="page">
      <!-- <div class="banner"></div>  -->
      <div class="main-width-container">
            <div class="text-center">
            <div class="article-title">活动管理员操作指南</div>
            <div class="cylct" @click="isShowPop=true"></div>
            </div>
            <div class="text-content">
            <p>参与调查体验活动的单位（学校或校外机构），可指定一名教师在活动平台注册并申请成为本次活动的管理员。通过活动平台，活动管理员可全面了解本单位组织参与活动的学生小组情况，并可申请活动材料包、进行优秀学生小组的推优，以及辅导教师的身份审核。学生小组的辅导教师与活动管理员可以为同一人。</p>
            <video src="https://cysccresource-new.kejie.org.cn/video/file/1guanliyuan.mp4" poster="../../assets/gly.poster.jpg" controls="controls" width="100%" height="100%" style="margin-bottom: 24px;"></video>
            <div class="text-title">
                <span>1.注册登录</span>
            </div>
            <p>成为活动管理员的教师，需先在活动平台注册成为辅导教师，然后申请成为管理员。若去年已经注册过教师账户，无需重新注册，可直接用此前注册过的账户登录。</p>
            <p>若去年已经是活动管理员的账户，登录活动平台后只需根据页面提示确认参与2023活动，即可自动延续管理员身份。</p>
            <div class="sub-text-title">
                <span>1.1注册</span>
            </div>
            <p>访问“青少年科学调查体验活动”官网( http://www.scienceday.org.cn/ )，点击右侧导航【注册】，将进入科创筑梦账号注册页面。注册时可使用邮箱或手机作为登录用户名。</p>
            <img src="https://cyscc.org/dctyhd/img/hd1.cb084b48.png" alt="">
            <div class="sub-text-title">
                <span>1.2登录</span>
            </div>
            <p>若已经有账号，点击官网右侧导航【登录】，进入科创筑梦登录页面，按提示输入【用户名】和【密码】，点击登录按钮，即可登录系统。</p>
            <img src="https://cyscc.org/dctyhd/img/hd2.13629dbe.png" alt="">
            <div class="text-title">
                <span>2.选择身份、完善信息</span>
            </div>
            <p>注册后首次登录，将进入身份选择界面。教师用户请选择【辅导老师】身份。注意：身份请勿选择错误。</p>
            <img src="https://cyscc.org/dctyhd/img/hd3.80b7884f.png" alt="">
            <p>在个人信息填写页面，请按要求完善相关信息，点击保存后，即完成教师账号的创建。注意，职业信息内的【单位】填写后将不可更改，务必准确填写。</p>

            <img src="https://cyscc.org/dctyhd/img/hd4.adb29954.png" alt="">
            <div class="text-title">
                <span>3.申请管理员(即申请实施单位)</span>
            </div>
            <p>每个参与单位（学校及校外机构）都可指定一名辅导老师成为本单位的活动管理员。在辅导教师申请成为管理员的同时，对应的单位也将成为实施单位。</p>
            <div class="tip-box">
                <div class="tip-title">小提示：</div>
                <div class="tip-content"> “实施单位”对应往年的“实施学校”，今年因允许校外机构参与，故改为“实施单位”。 </div>
            </div>
            <p style="color: rgb(82, 147, 211);">申请管理员(即申请实施单位)具体操作如下：</p>
            <p>在活动页面上，点击导航栏上的【活动管理】，再点击左侧导航的【申请管理员】按钮，即进入申请页面。</p>
            <div class="tip-box">
                <div class="tip-title">小提示：</div>
                <div class="tip-content"> 如果所在单位已经有了管理员，左侧按钮将是【变更管理员】。 </div>
            </div>
            <img src="https://newoa.cyscc.org/profile//castic/2024/06/20/87a6ad0f-456d-4425-b254-044c66beb250.jpg" alt="">
            <p>根据页面提示和要求完成相应的个人信息、上传申请文件即可完成申请，待省级管理员审核通过后即可成为实施单位，同时该提交申请的教师账号即成为本单位的活动管理员。</p>
            <div class="sub-text-title">
                <span>3.1变更管理员</span>
            </div>
            <p>每个单位（学校或校外机构）只能有一名管理员，如需更换原先的管理员，可指派另一名老师注册或登录账户，然后点击导航栏上的【活动管理】，再点击左侧导航的【更换管理员】，然后按照提示填写和上传要求的信息，等待审核通过后即可完成管理员的更换。</p>
            <img src="https://newoa.cyscc.org/profile//castic/2024/06/21/75c8b0af-1c51-4663-adaa-8f71f9583343.png" alt="">           
            
            <div class="sub-text-title">
                <span>3.2管理员/辅导教师身份切换</span>
            </div>
            <p>活动管理员同时拥有辅导教师权限，登陆活动页面后，可通过页面右上角的权限切换按钮进行辅导教师和管理员身份的切换。</p>
            <img src="https://cyscc.org/dctyhd/img/hd6.48544605.png" alt="">
            <div class="text-title">
                <span>4.小组管理</span>
            </div>
            <p>在切换为管理员身份的前提下，点击导航栏上的【小组管理】，即可进入小组管理页面，可查看本单位组织的活动小组情况、及推优情况。</p>
            <img src="https://cyscc.org/dctyhd/img/hd7.99857b8b.png" alt="">
            <p>对于已经全部完成报告提交的学生小组，学校管理员可进行推优（预计11月开始），被推优的小组将有机会成为全国优秀学生小组并获取证书。点击对应学生小组后的【推优】按钮即可完成推优。注意：只有当【推优】按钮为高亮状态，才可进行推优。</p>
            <p>在小组管理页面，点击【优先活动小组】则可查看全部已经推优的活动小组。如果管理员认为已推优的学生小组不合适，可以在这里找到相应的学生小组点击【撤销推优】。</p>
            <img src="https://cyscc.org/dctyhd/img/hd8.03f71892.png" alt="">
            <div class="text-title">
                <span>5.教师管理</span>
            </div>
            <p>在切换为管理员身份的前提下，点击导航栏上的【教师管理】，即可进入教师管理页面，可查看本单位注册过的辅导教师基本信息、查看提交的教育实践报告，并对教师进行推优。如果经核实并非本校老师，可点击删除按钮将相应的教师删除。</p>
            <img src="https://cyscc.org/dctyhd/img/hd9.a7e5b82f.png" alt="">
            <div class="sub-text-title">
                <span>5.1待审核教师</span>
            </div>
            <p>为防止恶意注册，辅导教师账号创建完成后，需管理员审核通过，之后学生在创建小组时才可看到并选择该教师。管理员注意及时审核。具体操作如下：</p>
            <p>在教师管理页面上，点击【待审核教师】，即可看到需要审核的教师账号信息。如教师确为本单位的教师，可点击【确认教师】按钮，否则可点击【删除教师】。</p>
            <img src="https://cyscc.org/dctyhd/img/hd10.2b939a98.png" alt="">
            <div class="text-title">
                <span>6.提交活动总结</span>
            </div>
            <p>在切换为管理员身份的前提下，点击导航栏上的【活动管理】，即可进入活动总结报告提交页面。点击提交总结，即可上传。</p>
            <img src="https://cyscc.org/dctyhd/img/hd11.fa8417d3.png" alt="">
            <p>如果上传的报告有误，可点击删除后，再重新上传。注意在报告提交截止时间前完成提交。</p>
            <img src="https://cyscc.org/dctyhd/img/hd12.b800b6c3.png" alt="">
            <div class="text-title">
                <span>7.申请活动资源包</span>
            </div>
            <p>在切换为管理员身份的前提下，点击导航栏上的【活动管理】，再点击左侧【申请学校资源】按钮，即申请页面。按页面提示填写本年度参与活动的预估学生与教师人数，及邮寄信息，提交后即可完成申请。主办方之后将根据所填写的信息，给符合条件的单位安排邮寄活动资源包。</p>
            <img src="https://cyscc.org/dctyhd/img/hd13.c2caebe6.png" alt="">

            <div class="text-title">
                <span>8.数据统计</span>
            </div>
            <p>在切换为管理员身份的前提下，点击导航栏上的【数据统计】，即可进入本单位活动数据统计页面，可分别查看学生数量、教师数量、小组数量等活动数据。</p>
            </div>

            <div class="pop-mask" v-show="isShowPop" @click="closeMask">
                <div class="pop-box" @click="closeMask1">
                    <img src="../../assets/images/guideline/gly-content.png" alt="">
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  
  export default {
    data () {
      return {
        isShowPop:false
      }
    },
    methods: {
        closeMask(){
            this.isShowPop = false
        },
        closeMask1(e){
            e.stopPropagation()
        }
   
    }
  }
  </script>
  
  <style scoped lang="less">
  .page {
    .pop-mask{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.2);
        z-index: 999;
        overflow: auto;
   .pop-box{
         width: 894px;
         margin: 40px auto;
        background-color: #fff;
        img{
            width: 100%;
        }

     }
   
    }
    width: 890px;
    margin-left: 31px;
    padding-top: 55px;
    padding-bottom: 55px;
    min-width: 890px;
    .main-width-container{
      width: 890px;
      margin: 0 auto;
      .cylct{
        width: 128px;
        height: 32px;
        background:url('../../assets//images/guideline/cylct.png') no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        margin: 0 auto;
      }
      .text-content{
        margin-top: 48px;
        img{
            margin-bottom: 24px;
        }
        p{
            font-size: 16px;
            line-height: 32px;
            color: #000;
            margin-bottom: 24px;
            text-indent: 2em;
        }
        .text-title{
            font-size: 22px;
            font-weight: 700;
            line-height: 30px;
            color: #5293D3;
            position: relative;
            margin-bottom: 24px;
        }
        .sub-text-title{
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: #5293D3;
            margin-bottom: 24px;
        }
        .tip-box{
            width: 100%;
            padding: 10px;
            border-radius: 8px;
            border: 1px solid #2084E1;
            background-color: #EAF5FF;
            margin-bottom: 24px;
            
            
            .tip-title{
                font-size: 16px;
                font-weight: 700;
                line-height: 32px;
                color: #000000;
                display: inline-block;
            }
            .tip-content{
                font-size: 16px;
                line-height: 32px;
                color: #000000;
                display: contents;
            }

        }
        .text-title::after{
            content: '';
            display: block;
            width: 30px;
            height:29px;
            background: url('../../assets//images/guideline/Vector.png') no-repeat;
            background-size: 100% 100%;
            position: absolute;
            top: 0;
            left: 0;
            vertical-align: middle;
        }
        .text-title span{
            margin-left: 34px;
        }
      }
    }
    .article-title {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    line-height: 36px;
    margin-bottom: 24px;
  }
 
 
  }
  




  
  @media (max-width: 768px) {
    .page {
      background: none;
      min-height: 100%;
      width: 100%;
      min-width: 100%;
      padding-top: 1.5rem;
      padding-bottom: 0;
      margin-left: 0;
      .pop-mask {
    .pop-box {
      width: 100%;
      position: absolute;
      top: 50%;
        transform: translateY(-50%);
      img{
        width: 100%;
      }

    }
  }
      .main-width-container{
        width: 100%;
        margin: 0 auto;
        .text-content{
        margin-top:0.48rem;

            img{
            width: 100%;
            }
            p{
            line-height: 1.8;
            color: #000;
        }
       
        }
      
    }
    .article-title {
      font-size: 0.28rem;
      font-weight: 600;
      color: #111111;
      line-height: 0.32rem;
      margin-bottom: 0.24rem;
    

  }
 }
  }

 

  </style>